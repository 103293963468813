/*  These two lines of CSS can't be used together, as they will break some elements. Uncomment the one you want to use and comment or delete the other one.
    white-space: pre-wrap - returns on a new line every word outside of the code block. No horizontal scrollbar. This breaks the lines of code, if you show them. https://developer.mozilla.org/en-US/docs/Web/CSS/white-space
    overflow-x: auto - The lines of code remain exactly the same as you typed. In case of overflow, it adds an horizontal scrollbar. This works with lines of code. https://developer.mozilla.org/en-US/docs/Web/CSS/overflow-x
*/
pre{
  /* white-space: pre-wrap; */  
   overflow-x: auto;
}

.highlight{
  font-size: 16px;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 1px;
  padding-bottom: 1px;
}

pre.lineo{
  color: white;
}

.highlight table td { padding: 5px; }
.highlight table pre { margin: 0; }
.highlight .c, .highlight .ch, .highlight .cd, .highlight .cpf {
  color: #75715e;
  font-style: italic;
}
.highlight .cm {
  color: #75715e;
  font-style: italic;
}
.highlight .c1 {
  color: #75715e;
  font-style: italic;
}
.highlight .cp {
  color: #75715e;
  font-weight: bold;
}
.highlight .cs {
  color: #75715e;
  font-weight: bold;
  font-style: italic;
}
.highlight .err {
  color: #960050;
  background-color: #1e0010;
}
.highlight .gi {
  color: #ffffff;
  background-color: #324932;
}
.highlight .gd {
  color: #ffffff;
  background-color: #493131;
}
.highlight .ge {
  color: #ffffff;
  font-style: italic;
}
.highlight .gr {
  color: #aa0000;
}
.highlight .gt {
  color: #aa0000;
}
.highlight .gh {
  color: #999999;
}
.highlight .go {
  color: #888888;
}
.highlight .gp {
  color: #555555;
}
.highlight .gs {
  font-weight: bold;
}
.highlight .gu {
  color: #aaaaaa;
}
.highlight .k, .highlight .kv {
  color: #66d9ef;
  font-weight: bold;
}
.highlight .kc {
  color: #66d9ef;
  font-weight: bold;
}
.highlight .kd {
  color: #66d9ef;
  font-weight: bold;
}
.highlight .kp {
  color: #66d9ef;
  font-weight: bold;
}
.highlight .kr {
  color: #66d9ef;
  font-weight: bold;
}
.highlight .kt {
  color: #66d9ef;
  font-weight: bold;
}
.highlight .kn {
  color: #f92672;
  font-weight: bold;
}
.highlight .ow {
  color: #f92672;
  font-weight: bold;
}
.highlight .o {
  color: #f92672;
  font-weight: bold;
}
.highlight .mf {
  color: #ae81ff;
}
.highlight .mh {
  color: #ae81ff;
}
.highlight .il {
  color: #ae81ff;
}
.highlight .mi {
  color: #ae81ff;
}
.highlight .mo {
  color: #ae81ff;
}
.highlight .m, .highlight .mb, .highlight .mx {
  color: #ae81ff;
}
.highlight .se {
  color: #ae81ff;
}
.highlight .sb {
  color: #e6db74;
}
.highlight .sc {
  color: #e6db74;
}
.highlight .sd {
  color: #e6db74;
}
.highlight .s2 {
  color: #e6db74;
}
.highlight .sh {
  color: #e6db74;
}
.highlight .si {
  color: #e6db74;
}
.highlight .sx {
  color: #e6db74;
}
.highlight .sr {
  color: #e6db74;
}
.highlight .s1 {
  color: #e6db74;
}
.highlight .ss {
  color: #e6db74;
}
.highlight .s, .highlight .sa, .highlight .dl {
  color: #e6db74;
}
.highlight .na {
  color: #a6e22e;
}
.highlight .nc {
  color: #a6e22e;
  font-weight: bold;
}
.highlight .nd {
  color: #a6e22e;
  font-weight: bold;
}
.highlight .ne {
  color: #a6e22e;
  font-weight: bold;
}
.highlight .nf, .highlight .fm {
  color: #a6e22e;
  font-weight: bold;
}
.highlight .no {
  color: #66d9ef;
}
.highlight .bp {
  color: #f8f8f2;
}
.highlight .nb {
  color: #f8f8f2;
}
.highlight .ni {
  color: #f8f8f2;
}
.highlight .nn {
  color: #f8f8f2;
}
.highlight .vc {
  color: #f8f8f2;
}
.highlight .vg {
  color: #f8f8f2;
}
.highlight .vi {
  color: #f8f8f2;
}
.highlight .nv, .highlight .vm {
  color: #f8f8f2;
}
.highlight .w {
  color: #f8f8f2;
}
.highlight .nl {
  color: #f8f8f2;
  font-weight: bold;
}
.highlight .nt {
  color: #f92672;
}
.highlight {
  color: #f8f8f2;
  background-color: #49483e;
}

figure.highlight{
  position: relative;
}

figure.highlight pre code[data-lang]::before, div.highlight pre code[data-lang]::before{
  content: attr(data-lang);
  text-transform: uppercase;
  position: absolute;
  color: #ebebeb;
  right: 34px;
  top: -10px;
  padding-left: 7.5px;
  padding-right: 7.5px;
  border: 1px solid #828282;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  min-width: 40px;
  text-align: center;
  background-color: #49483e;
}